import React, { useState } from "react"
import styled from "styled-components"
import T from "./multiLanguage/translate"
import { color, fontSize } from "../provider/theme"
import OriginalLink from "./link"
import { graphql, useStaticQuery } from "gatsby"
import TContrib from "./multiLanguage/translateContrib"
import { breakpoint } from "styled-components-breakpoint"
import { usePageContext } from "../provider/pageContext"
import { useLocationContext } from "../provider/locationProvider"

const SubList = styled.ul`
  background-color: ${color(`white`)};
  display: none;

  ${breakpoint(`desktop`)`
      position: absolute;
      top: 100%;
      left: 0;
      width: auto;
      z-index: 2;
  `}
`

const SubItem = styled.li`
  padding: 5px;
  white-space: nowrap;
  font-size: ${fontSize(`small`)};
  text-transform: lowercase;
  text-align: right;

  ${breakpoint(`desktop`)`
    padding: 5px 20px;
    text-align: left;
  `}
`

const List = styled.ul`
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: ${props => (props.homepage ? `center` : `flex-end`)};
  margin: 0 auto;
  width: ${props => (props.homepage ? `600px` : `100%`)};
  max-width: 100%;
  justify-content: space-between;
  font-size: ${fontSize(`large`)};
  margin-bottom: ${props => (props.homepage ? `0` : `15px`)};

  ${breakpoint(`desktop`)`
    flex-direction: row;
  `}
`

const Item = styled.li`
  position: relative;
  display: ${props => (props.homepage || props.open ? `block` : `none`)};
  :hover ${SubList} {
    display: block;
  }

  ${breakpoint(`desktop`)`
    display: block;
  `}
`

const Link = styled(OriginalLink)`
  position: relative;
  color: ${props => color(props.highlight ? `black` : `grey`)};

  &:after {
    content: "";
    position: absolute;
    left: 0;
    top: 100%;
    width: ${props => (props.active ? `100%` : 0)};
    height: 2px;
    background-color: ${props => color(props.highlight ? `black` : `grey`)};
    transition: width 0.2s;
  }

  &:hover:after {
    width: 100%;
  }
`

const Hamburger = styled.span`
  width: 40px;
  height: 40px;
  position: relative;
  cursor: pointer;
  display: block;
  margin-left: 20px;
`

const Steak = styled.span`
  position: absolute;
  top: ${props => props.pos * 10}px;
  left: 5px;
  height: 2px;
  width: 30px;
  background-color: ${color(`white`)};
  transform-origin: center center;
  opacity: 1;
  transition: all 0.2s;
  ${props =>
    props.open &&
    props.pos === 2 &&
    `
    transform: rotate(180deg);
    opacity: 0;
  `}
  ${props =>
    props.open &&
    props.pos === 1 &&
    `
    transform: rotate(225deg);
    top:50%;
  `}
  ${props =>
    props.open &&
    props.pos === 3 &&
    `
    transform: rotate(135deg);
    top:50%;
  `}
`

const MenuLi = styled.li`
  background-color: ${color(`black`)};
  color: ${color(`white`)};
  padding: 0 20px;
  display: ${props => (props.homepage ? `none` : `flex`)};
  align-items: center;

  ${breakpoint(`desktop`)`
    display: none;
  `}
`

export default function Menu({ homepage = false }) {
  const {
    allPrismicCategory: { nodes: categories },
  } = useStaticQuery(query)
  const [open, setOpen] = useState(false)
  const { cleanedPath = null } = usePageContext()
  const { path } = useLocationContext()
  const rPath = cleanedPath || path

  const toggle = () => setOpen(value => !value)
  return (
    <List homepage={homepage}>
      <MenuLi homepage={homepage} onClick={toggle}>
        Menu
        <Hamburger>
          <Steak pos={1} open={open} />
          <Steak pos={2} open={open} />
          <Steak pos={3} open={open} />
        </Hamburger>
      </MenuLi>
      <Item homepage={homepage} open={open}>
        <Link to="/biographie/" active={rPath === `/biographie/`}>
          <T>Biographie</T>
        </Link>
      </Item>
      <Item homepage={homepage} open={open}>
        <T>Oeuvres</T>
        <SubList>
          {categories.map(category => (
            <SubItem key={category.id}>
              <Link
                to={`/${category.uid}`}
                active={rPath === `/${category.uid}`}
              >
                <TContrib data={category.data} field="name" />
              </Link>
            </SubItem>
          ))}
        </SubList>
      </Item>
      <Item homepage={homepage} open={open}>
        <Link to="/expositions/" active={rPath === `/expositions/`}>
          <T>Expositions</T>
        </Link>
      </Item>
      <Item homepage={homepage} open={open}>
        <Link to="/contact/" active={rPath === `/contact/`}>
          <T>Contact</T>
        </Link>
      </Item>
    </List>
  )
}

const query = graphql`
  query {
    allPrismicCategory(
      filter: { data: { categorie_parent: { id: { eq: null } } } }
    ) {
      nodes {
        data {
          name_en
          name_fr
        }
        uid
      }
    }
  }
`

import React, { createContext, useContext } from "react"

const PageContext = createContext()

export default function PageContextProvider({ context, children }) {
  return <PageContext.Provider value={context}>{children}</PageContext.Provider>
}

export function usePageContext() {
  return useContext(PageContext)
}

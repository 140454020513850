import React from "react"
import T from "./multiLanguage/translate"
import styled from "styled-components"

const StyledFooter = styled.footer`
  display: block;
  text-align: right;
`

export default function Footer() {
  return (
    <StyledFooter>
      © {new Date().getFullYear()}, <T>copyright</T>
    </StyledFooter>
  )
}

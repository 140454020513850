module.exports = {
  fr: {
    copyright: `Marjolaine Robert de Saint Vincent. Tous droits réservés`,
    name: `nom`,
    contact_accroche: `Vous pouvez me contacter en utilisant le formulaire ci-dessous:`,
    send_message: `Envoyer le message`,
    error_captcha: `Erreur: veuillez cocher le reCAPTCHA`,
    message_sent: `Message envoyé`,
  },
  en: {
    copyright: `Marjolaine Robert de Saint Vincent. All rights reserved`,
    error_captcha: `Error: please check reCAPTCHA`,
    biographie: `biography`,
    oeuvres: `works`,
    expositions: `exhibitions`,
    contact_accroche: `You can contact me using the form below:`,
    accueil: `homepage`,
    send_message: `Send message`,
    message_sent: `Message sent`,
  },
}

module.exports = {
  languages: {
    all: [`fr`, `en`],
    default: `fr`,
  },
  theme: {
    breakpoints: {
      xs: 0,
      desktop: 992,
    },
    color: {
      black: `#111`,
      grey: `#5a5a5a`,
      white: `#FFF`,
    },
    fontSize: {
      small: `0.75em`,
      regular: `1em`, // 16px
      large: `1.5rem`, // 24px
      x_large: `2.5rem`, // 40px
      xx_large: `3.5rem`, // 40px
    },
  },
}

import { Link as GatsbyLink } from "gatsby"
import React from "react"
import { usePageContext } from "../provider/pageContext"

export default function Link({
  children,
  to,
  switchLanguage = true,
  highlight = false,
  active = false,
  ...props
}) {
  const { lang = null } = usePageContext()
  const translatedTo = `${lang && switchLanguage ? `/${lang}` : ``}${to}`
  return (
    <GatsbyLink {...props} to={translatedTo}>
      {children}
    </GatsbyLink>
  )
}

import React, { createContext, useContext } from "react"

const LocationContext = createContext()

export default function LocationProvider({ location, path, children }) {
  return (
    <LocationContext.Provider value={{ ...location, path }}>
      {children}
    </LocationContext.Provider>
  )
}

export function useLocationContext() {
  return useContext(LocationContext)
}

import React from "react"
import styled from "styled-components"
import { usePageContext } from "../../provider/pageContext"
import { languages } from "../../../../config/config"
import Link from "../link"
import { useLocationContext } from "../../provider/locationProvider"

const Container = styled.div`
  width: 100%;
  display: flex;
`

const Line = styled.div`
  flex: 1;
  position: relative;
  margin-right: 10px;

  :after {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 2px;
    background-color: black;
  }
`

const Ul = styled.ul`
  display: flex;
  flex-direction: row;
`

const Li = styled.li`
  text-transform: uppercase;

  :not(:last-of-type) {
    :after {
      padding: 0 5px;
      content: "/";
    }
  }
`

const Active = styled.span`
  text-decoration: underline;
`

export default function LangageSwitcher() {
  const { lang = languages.default, cleanedPath = null } = usePageContext()
  const { path } = useLocationContext()

  const findUrl = language => {
    if (language === languages.default) {
      return cleanedPath
    }
    return `/${language}${cleanedPath || path}`
  }

  return (
    <Container>
      <Line />
      <Ul>
        {languages.all.map((language, i) => {
          const url = findUrl(language)
          return (
            <Li key={i}>
              {language === lang && <Active>{language}</Active>}
              {language !== lang && (
                <Link to={url} switchLanguage={false}>
                  {language}
                </Link>
              )}
            </Li>
          )
        })}
      </Ul>
    </Container>
  )
}

import { createGlobalStyle } from "styled-components"
import { color } from "../provider/theme"

const GlobalStyle = createGlobalStyle`
  @font-face {
      font-family: effloresce;
      src: url('/fonts/effloresce.ttf');
      font-weight: 500;
      font-display: fallback;
  }
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: transparent;
    
    :focus {
      outline: none
    }
  }

  html, body {
    font-family: effloresce;
    color: ${color(`black`)};
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    line-height: 1.5;
    -webkit-font-smoothing: antialiased;
  }
  
  [data-rmiz-overlay] > div {
    width: 100% !important;
    height: 100% !important;
    left: 0!important;
    top: 0!important;
  }
  
  
  #___gatsby > div {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
  }
  
  a {
    color: inherit;
    text-decoration: none;
  }
  
  ul {
    margin: 0;
    list-style: none;
    padding: 0;
  }
`

export default GlobalStyle

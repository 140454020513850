import React, { useContext } from "react"
import {
  ThemeContext,
  ThemeProvider as StyledThemeProvider,
} from "styled-components"
import { theme } from "../../../config/config"

export default function ThemeProvider({ children }) {
  return <StyledThemeProvider theme={theme}>{children}</StyledThemeProvider>
}

export function useThemeContext() {
  return useContext(ThemeContext)
}

const fromTheme = keyTheme => key => props => props.theme[keyTheme][key]

export const color = fromTheme(`color`)
export const fontSize = fromTheme(`fontSize`)

import styled from "styled-components"

const PageContainer = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: 0 20px;
  margin: 0 auto;
  flex: 1;
  display: flex;
  flex-direction: column;
`

export default PageContainer

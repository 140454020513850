/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"
import PageContainer from "./pageContainer"
import LangageSwitcher from "./multiLanguage/LangageSwitcher"
import Provider from "../provider/provider"
import GlobalStyle from "./globalStyle"
import styled from "styled-components"
import Menu from "./menu"
import Link from "./link"
import { fontSize } from "../provider/theme"
import Footer from "./footer"
import { breakpoint } from "styled-components-breakpoint"
import SEO from "./seo"

const Main = styled.main`
  max-width: 1140px;
  width: 100%;
  margin: 0 auto;
  flex: 1;
`

const Logo = styled.div`
  text-align: center;
  font-size: ${fontSize(`large`)};
  margin-bottom: 15px;

  ${breakpoint(`desktop`)`
    font-size: ${fontSize(`x_large`)};
    margin-bottom: 50px;
  `}
`

const Spacer = styled.div`
  flex: 1;
`

const PageContent = styled.div`
  z-index: 1;
`

const Layout = ({ children, title, ...props }) => {
  return (
    <Provider {...props}>
      <GlobalStyle />
      <SEO title={title} />
      <PageContainer>
        <LangageSwitcher />
        <Main>
          <Logo>
            <Link to="/">Marjolaine Robert de Saint Vincent</Link>
          </Logo>
          <Menu />
          <PageContent>{children}</PageContent>
        </Main>
        <Spacer />
        <Footer />
      </PageContainer>
    </Provider>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout

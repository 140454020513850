import React from "react"
import { languages } from "../../../../config/config"
import { usePageContext } from "../../provider/pageContext"

export default function TContrib({ data, field }) {
  const { lang = languages.default } = usePageContext()
  const tField = `${field}_${lang}`

  if (!(tField in data)) {
    if (field in data) {
      return data[field]
    }
    return null
  }

  if (data[tField] && data[tField].html) {
    return <div dangerouslySetInnerHTML={{ __html: data[tField].html }} />
  }

  if (typeof data[tField] === 'string') {
    return data[tField]
  }

  return null
}

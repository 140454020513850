import { usePageContext } from "../../provider/pageContext"
import translation from "../../../../config/translation"
import { languages } from "../../../../config/config"

function capitalize(str) {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

function conditionalCapitalize(str, condition = false) {
  return condition ? capitalize(str) : str
}

function isCapitalize(str) {
  const char = str.charAt(0)
  return char.toUpperCase() === char
}

export function __(text, lang = languages.default) {
  if (!(lang in translation)) {
    return text
  }

  const key = text.toLowerCase()

  if (!(key in translation[lang])) {
    return text
  }

  const word = translation[lang][key]
  return conditionalCapitalize(word, isCapitalize(text))
}

export default function T({ children }) {
  const { lang = languages.default } = usePageContext()

  return __(children, lang)
}

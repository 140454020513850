import React from "react"
import PageContextProvider from "./pageContext"
import ThemeProvider from "./theme"
import LocationProvider from "./locationProvider"
import config from "../../../config/config"

export default function Provider({ pageContext, location, path, children }) {
  return (
    <LocationProvider location={location} path={path}>
      <PageContextProvider context={pageContext}>
        <ThemeProvider theme={config.theme}>{children}</ThemeProvider>
      </PageContextProvider>
    </LocationProvider>
  )
}
